body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* 
html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Container {
  width: 100%;
  height: 100%;
  position: relative;
}

/* Navbar Section */

.navbar {
  position: relative;
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 0rem;
  box-shadow: 5px 1px 23px #c9c9c9, -8px -5px 23px #ffffff;
}

.navbarHeading {
  text-decoration: none;
  font-size: 2.5rem;
  font-weight: 700;
  color: #4785ff;
}

.navbarListing {
  display: flex;
  list-style: none;
  gap: 3rem;
  align-items: center;
}

.navbarListing a {
  text-decoration: none;
  font-weight: 700;
}


.listItem {
  color: #101218;
}

.navbarCallPosition {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbarCallLink {
  text-decoration: none;
  text-align: left;
}

.navbarSetting {
  padding: 0;
  margin: 0;
}

.navbarCallIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #4785ff;
}

.navbarCallDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.4rem;
}

.navbarCallDetails p {
  margin: 0;
  color: #101218;
}

.navbarCallDetails h5 {
  margin: 0;
  font-size: 2rem;
  color: #101218;
}

.navbar ul li a:hover {
  color: #4785ff;
}

.navbarMenu {
  cursor: pointer;
  display: none;
}

.navbarListing.active {
  display: flex;
}

.activeLink {
  color: #4785ff;
  font-weight: bold;
}

.navbarListing a {
  transition: color 0.3s ease;
}

@media (max-width: 1000px) {
  .navbar {
    justify-content: space-around;
  }

  .navbarMenu {
    display: block;
  }

  .navbarCallLink {
    display: none;
  }

  .navbarListing {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100px;
    right: 0;
    background-color: #ffffff;
    width: 100%;
    padding: 1rem;
    text-align: right;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }

  .navbarListing.active {
    display: flex;
  }

  .navbarListing li {
    margin-bottom: 1rem;
  }

  .navbarHeading a {
    font-size: 2rem;
    margin-left: 1rem;
  }
}

/* HOME SECTION */

.herosection {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.detailsSection {
  height: 100%;
  width: 58%;
  padding: 3.8rem 7rem 0rem 5rem;
}

.cardsSection {
  height: 100%;
  width: 42%;
  display: flex;
  gap: 2rem;
  padding-right: 5rem;
}

.spanHeading {
  color: #ffa4d5;
  font-size: 1.25rem;
}

.detaildsHeading {
  font-size: 3.9rem;
  margin-block: 1.25rem;
}

.subheading {
  font-size: 1.2rem;
}

.buttonSection {
  display: flex;
  align-items: center;
  gap: 2.2rem;
  margin-block: 2.25rem;
}

.exploreButton {
  height: 3rem;
  width: 10rem;
  color: white;
  border-radius: 2rem;
  font-size: 1.2rem;
  outline: none;
  border: none;
  padding-inline: 1rem;
}

.exploreButton:hover {
  background-color: rgb(77, 77, 255);
}

.trustedcompaniesSeciton {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2.125rem;
}

.playsize {
  cursor: pointer;
}

.testheading {
  font-weight: 500;
  font-size: 1.125rem;
}

.conpanisesLogo {
  padding-top: 1rem;
  padding-right: 1rem;
  gap: 0.2rem;
  display: flex;
  justify-content: space-between;
}

.leftpane,
.rightpane {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  min-height: 100%;
}

.how {
  background: #aee5ff;
  border-bottom-left-radius: 1.3rem;
  border-bottom-right-radius: 1.3rem;
  padding: 1rem 2rem;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  text-align: left;
  padding: 2rem 3rem;

  h5 {
    margin-block: 10px;
    font-weight: 700;
    font-size: 1rem;
  }

  span {
    font-size: 0.9rem;
  }

  img {
    max-width: 40px;
  }
}

.cardimgg {
  object-fit: cover;
  min-width: 60px;
  height: 100%;
  border-radius: 1.3rem;
}

.cardimg {
  max-height: 18.5rem;
  flex-grow: 1;
}

.ratingcard {
  background: black;
  border-bottom-left-radius: 1.3rem;
  border-bottom-right-radius: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  min-height: 120px;
  font-weight: 500;
  flex-direction: column;
  gap: 1.25rem;
  text-align: left;
}

.totalusers {
  background: #f1f1f1;
  border-top-left-radius: 1.3rem;
  border-top-right-radius: 1.3rem;
  padding: 2.4rem;
  color: black;

  span {
    font-weight: 600;
    font-size: 1.125rem;
  }

  h5 {
    font-size: 1rem;
  }
}

.languagecard {
  background: #c3ffd2;
  border-top-left-radius: 1.3rem;
  border-top-right-radius: 1.3rem;
  padding: 1.5rem 2.4rem 1.35rem 2.4rem;
  color: black;
  display: flex;
  flex-direction: column;
  height: 100%;

  h5 {
    font-weight: 600;
    font-size: 1.125rem;
  }
}

.langTitle {
  border-bottom: 1px solid black;
  padding-block: 5px;
}

.langBUtton {
  height: 34px;
  background: #ff7c75;
  color: white;
  border-radius: 30px;
  font-size: 12px;
  outline: none;
  border: none;
  padding-inline: 15px;
  margin-top: 22px;
}

.cardimgg,
.collageimg,
.commentIcon {
  width: 100%;
  height: 18rem;
  max-width: 100%;
  border-radius: 1rem;
}

.playimg {
  max-width: 100%;
}

.logosize {
  min-width: 24%;
  width: 100px;
  max-width: 60%;
  height: auto;
  aspect-ratio: 250/140;
}

/* whyCodeBee Section */

.whyCodeBeeSection {
  background-color: #aee5ff;
  display: flex;
  flex-wrap: wrap;
  background-color: #aee5ff;
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.2) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 1px, transparent 1px);
  background-size: 100px 100px;
  padding: 3rem;
}

.whyCodeBee {
  margin: 6rem 0rem 6rem 0rem;
  border-radius: 1rem;
  height: 100%;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-content: center;
  align-items: center;
  box-shadow: -20px -20px 40px #789eb0, 20px 20px 40px #e4ffff;
}

.collageImage {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  gap: 1.5rem;
  border-radius: 1rem;
  margin: 1rem;
  padding: 1rem;
}

.diveInButton {
  background-color: #ff7c75;
  border-radius: 2rem;
  border: none;
  padding: 0.45rem 1rem;
  color: white;
}

.buttonAndArrow {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 2rem;
  width: 85%;
  border-top: 1px solid gray;
  margin-left: 2rem;
  padding: 1.5rem 0rem;
}

.arrow {
  padding-left: 0.5rem;
}

.whyChooseUs {
  margin: 6rem 7rem 6rem 3rem;
  /* border: 1px solid black; */
  height: 100%;
  width: auto;
}

.whyChooseUs h5 {
  font-weight: bold;
  font-size: 3.3rem;
  text-align: left;
  padding-bottom: 1rem;
}

.whyChooseUsText {
  font-size: 1.3rem;
  font-weight: 500;
  padding-bottom: 0.4rem;
}

.whyChooseUs span {
  text-align: left;
  font-size: 1.1rem;
}

.listItems {
  margin: 2rem 0rem;
}

.bulletPoints {
  font-weight: 600;
  font-size: large;
}

.bulletPoints svg {
  margin-right: 1rem;
}

.enrollNow {
  border-top: 1px solid black;
  padding: 2rem 0rem;
  width: 95%;
  text-align: left;
  font-size: 1.1rem;
}

.enrollNow button {
  border-radius: 2rem;
  padding: 0.55rem 1.5rem;
  border: none;
  text-align: center;
  font-size: 1rem;
  margin: 1rem 0rem;
}

/* Clients Section */

.clientsThoughts {
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.clientSaying {
  margin: 2rem 5rem 3rem 5rem;
}

.clientSaying h4 {
  color: #ff7c75;
  font-size: 3rem;
  text-align: left;
}

.clientCards {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(300px, 1fr)
  ); /* Responsive grid */
  gap: 3rem;
  margin: 0rem 5rem 0rem 5rem;
}

.clientCard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clientsReview {
  border: 4px solid #4785ff;
  border-radius: 1rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  text-align: center;
}

.clientsReview p {
  margin: 1rem 0;
}

.clientsComments {
  margin-top: 2.5rem;
}

.clientsComments > p {
  text-align: center;
}

.clientsName {
  font-size: 1.25rem;
  font-weight: bold;
}

.commentIcon {
  height: 5rem;
  width: 5rem;
}

.iconPng {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
}

.clientImg {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  bottom: -2.5rem;
  left: 50%; /* Center it horizontally */
  transform: translateX(-50%); /* Center it perfectly */
}

/* FAQ SECTION */

.faqSection {
  display: flex;
  flex-direction: column;
  padding: 4rem 6rem 4rem 6rem;
  background-color: #f2f2f2;
}

.faq1 h1 {
  font-size: 3.5rem;
  margin: 1rem;
  margin-bottom: 2rem;
  color: #101218;
  font-weight: bold;
}

.wrapper {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  gap: 20px;
}

.accordian {
  width: 100%;
  border-radius: 2rem;
}

.item {
  background: white;
  margin-bottom: 5px;
  padding: 10px 20px;
  border-radius: 0.7rem;
  margin-top: 1.5rem;
  padding: 1rem;
}

.title {
  color: #101218;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.title h2 {
  font-size: 1.3rem;
  color: #101218;
}

.content {
  color: #4d4d4d;
  max-height: 0;
  overflow: hidden;
  transition: all 0s cubic-bezier(1, 0, 1, 0);
}

.content.show {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

/* About Us Section */

.aboutCodebee {
  display: flex;
  flex-direction: column;
}

.aboutCodebee1 {
  color: #ffa4d5;
  margin: 3rem 7rem;
}

.aboutCodebee1 p {
  font-size: 1.3rem;
}

.visionDetails {
  display: grid;
  grid-template-columns: 60% 40%;
  margin: 0rem 7rem 7rem 7rem;
}

.vision {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vision h4 {
  font-size: 4.5rem;
}

.vision p {
  padding-top: 0.5rem;
  text-align: center;
  width: 17rem;
  font-size: 1.1rem;
}

.visionImage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo1 {
  width: 100%;
  height: 100%;
}

/* Shaping CodeBee */

.shapingCodebee {
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
}

.shapingCodebee1 {
  text-align: center;
  margin: 5rem;
}

.shapingCodebee1 h4 {
  font-size: 3rem;
}

.shapingCodebee1 p {
  font-size: 1.2rem;
  margin-top: 1rem;
}

.shapingCodebee2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 5rem;
}

.shapingCodebee3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.shapingCodebee3 p {
  margin-top: 1rem;
  font-weight: bold;
  text-align: center;
}

.hexagonImage {
  width: 9rem;
  height: 7rem;
}

/* ************************ Inspiring Content SECTION  *************************************************/

.inspiringContent {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 5rem auto;
}

.inspiringContent1 h4 {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
}

.inspiringContent2 {
  display: flex;
  justify-content: center;
  justify-content: space-around;
  flex-wrap: wrap;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  padding-top: 5vh;
  padding-bottom: 3vh;
}

.inspiringContent3 {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  width: 400px;
}

.inspiringContent4 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #a3a3f5;
  height: 55px;
  width: 55px;
  border-radius: 50%;
}

.inspiringContent44 {
  margin-left: 1.6vw;
  width: 280px;
}

.inspiringContent44 p {
  text-align: left;
  font-weight: 600;
  font-size: 1.4rem;
}

.inspiringContent44 span {
  font-size: 1rem;
}

.inspiringContent6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.inspiringContent6 p {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.5rem;
  margin: 0;
  font-size: 1.2rem;
  width: 100%;
  font-stretch: wider;
  text-align: center;
}

.inspiringContent6 img {
  width: 100%;
  height: auto;
  border-radius: 1rem;
}

/* OUR TEAM SECTION */

.teamSection {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
}

.teamSection > h4 {
  padding: 5rem 0rem 2rem 6rem;
  font-size: 3.5rem;
}

.ourTeam {
  display: flex;
  flex-direction: column;
}

.cardWrapper {
  padding: 2rem 9rem;
  margin-bottom: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 3rem;
}

.profileCard {
  width: 380px;
  height: 500px;
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5%;
  padding: 1rem 1rem;
}

.profileImage {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  object-fit: cover;
}

.cardDetails h4 {
  text-align: center;
  padding-top: 1rem;
}

.cardDetails p {
  text-align: center;
  font-size: 0.7rem;
}

.cardDetails span {
  text-align: center;
  display: flex;
  justify-content: center;
  padding-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
}

.iconProfile {
  margin: 0.5rem 0rem;
  display: flex;
  gap: 3rem;
}

/* Gallery SECTION  */

.Gallery {
  color: #ffa4d5;
  margin: 3rem 7rem;

  p {
    font-size: 1.5rem;
  }
}

h1 {
  width: auto;
  height: auto;
  color: #ffa4d5;
  text-align: left;
  margin-left: 5%;
  margin-top: 80px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  font-family: "DM Sans", sans-serif;
}

.frame {
  width: 90%;
  max-width: 1180px;
  height: auto;
  margin: 80px auto 20px;
  border-radius: 16px;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.frame.visible {
  opacity: 1;
}

.gallery {
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: start;
  align-items: center;
}

.image-container {
  width: 100%;
  max-width: 1180px;
  height: 470px;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
}

.image-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: filter 0.3s ease-in-out;
}

.image-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease-in-out;
}

.image-container:hover img {
  filter: blur(4px);
}

.image-container:hover .overlay {
  opacity: 1;
}

.description {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: auto;
  max-width: 90%;
  color: white;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 8px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.image-container:hover .description {
  opacity: 1;
}

/* Footer Section  */

.footer {
  width: 100%;
  height: 100%;
  position: relative;
}

.footer1 {
  border-top: 1px solid #c1c1c1;
  border-bottom: 1px solid #c1c1c1;
  margin: 5rem 7rem 1.5rem 7rem;
  padding-top: 2rem;
  padding-bottom: 3rem;
  gap: 1rem;
}

h2 {
  color: #4785ff;
  font-size: 2.5rem;
  font-weight: 500;
  margin: 0 0 1rem 0;
  padding: 0;
}

.footer2 {
  display: flex;
  justify-content: space-between;
  margin: 0rem 7rem 2rem 7rem;
}

.footer3 {
  display: flex;
  justify-content: space-between;
}

.footer4 {
  display: flex;
  flex-direction: column;
}

.footer4 span {
  text-decoration: none;
  margin-bottom: 0.5rem;
  color: #101218;
}

.footer4 h5 {
  margin: 0;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.footer5 {
  display: flex;
  flex-direction: column;
}

.footer5 a {
  text-decoration: none;
  margin-bottom: 0.5rem;
  color: #101218;
  cursor: pointer;
}

.footer5 h5 {
  margin: 0;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.footer6 {
  display: flex;
  flex-direction: column;
}

.footer7 {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 1.5rem 0rem;
  gap: 1rem;

  a {
    text-decoration: none;
  }
}

.footer8 {
  display: flex;
  justify-content: space-between;

  a {
    background-color: #4785ff;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Suraj Part */

.programSection {
  width: 100%;
  height: 100%;
}

.pSection {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.pSection > span {
  color: #FFA4D5;
  margin: 5rem 7rem 2rem 8rem;
  font-size: 1.5rem;
}


.pSection h5 {
  font-size: 3.8rem;
  font-weight: 600;
  margin: 0.8rem 7rem 2rem 8rem;
  text-align: center;
}


.pSection p {
  line-height: 2.5rem;
  font-size: 1.3rem;
  text-align: center;
  width: 100%;
}

.pSection1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  margin-top: 3rem;
  margin-bottom: 6rem;
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
}

.our_instructors {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
}

.pSection2 {
  background-color: #F9FAFC;
  padding: 1.5rem;
  border-radius: 4%;
  border: 1px solid #E1E9F8;
  width: 100%;
}

.pSection2 img {
  object-fit: cover;
  width: 100%;
  height: auto;
  border-radius: 4%;
}


.pSection2 h6 {
  font-size: 1.2rem;
  margin-top: 1rem;
  font-weight: 600;
}

.pSection3 {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
}

.pSection4 {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 1rem;
}

.our_programs {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #F2F2F2;
}


.original1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.3rem;
}

.head1 {
  text-align: center;
  color: #ffa4d5;
  margin: 1rem;
  padding-top: 3rem;
}

.cat1 {
  text-align: center;
  font-weight: bold;
  font-size: 3rem;
  margin-top: 1rem;
}

.explore {
  text-align: center;
  font-size: 1.3rem;
  width: 100%;
  max-width: 900px;
  text-wrap: pretty;
  margin: 0 auto;
  padding-bottom: 5rem;
  padding-top: 2rem;
}

.classes {
  font-size: 1rem;
  font-weight: 600;
}

.classespara {
  margin: 1rem;
  font-weight: 150;
  margin-left: 0;
}

.new3 {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.news0 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}

.news1 {
  display: flex;
}

.iconSVG {
  background-color: #a3a3f5;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.news {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 3rem;
  place-content: center;
}

.news2 {
  padding-left: 2rem;
  text-align: left;
  font-size: 0.8rem;
}

.bottom {
  text-align: center;
  font-size: 1rem;
  margin: 4rem auto 5rem auto;
}





.instructors {
  display: flex;
  margin-top: 2rem;
  margin-bottom: 6rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}


.card {
  background: #fff;
  border-radius: 20px;
  box-sizing: border-box;
  box-shadow: 0 15px 35px #A3A3F5;
  height: 450px;
  width: 300px;
  position: relative;
  padding: 20px;
  transition: 1s;
}

.card > h1 {
  text-align: center;
  margin: 0;
  padding: 0;
  margin-top: 3rem;
  margin-bottom: 0.2rem;
}

.card >p {
  text-align: center;
  font-size: 14pt;
  margin: 0;
  padding: 0;
  margin-top: 0.5rem;
}

.card .card-cover {
  background: #000;
  border-radius: 20px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform-origin: left;
  transition: 1s;
  transform-style: preserve-3d;
}

.card img {
  border-radius: 20px;
  transition: 1s;
  transform-style: preserve-3d;
  height: 450px;
  width: 300px;
  aspect-ratio: 4/3;
  object-fit: cover;
}

.card:hover {
  transform: translateY(-100px);
  
}

.card:hover .card-cover {
  transform: rotateY(-180deg);
}

.card:hover img {
  opacity: 0.4;   
}





/* .cards {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10rem;
  margin-bottom: 5rem;
} */

/* p.hea1 {
  padding-top: 0.5rem;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}

p.hea2 {
  padding-top: 0.3rem;
  text-align: center;
  font-size: 0.8rem;
}

p.hea3 {
  padding-top: 0.5rem;
  text-align: center;
  font-size: 0.5rem;
} */

.learning-methods {
  background-color: #a3a3f5;
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.2) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 1px, transparent 1px);
  background-size: 100px 100px;
  padding: 6.5rem;
}

.learning-methods__container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.learning-methods__title {
  font-size: 18px;
  font-weight: 500;
  color: #ffff;
  margin-bottom: 10px;
}

.learning-methods__heading {
  font-size: 36px;
  font-weight: 700;
  color: #ffff;
  margin: 0;
}

.learning-methods__text {
  font-size: 16px;
  font-weight: 400;
  color: #ffff;
  margin-bottom: 30px;
}

.learning-methods__cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 0px;
  justify-items: center;
}

.learning-methods__card {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: white;
  gap: 0;
  width: 250px;
  height: 80px;
  transition: all 0.3s ease;
}

.learning-methods__card-icon {
  background-color: #ffff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.learning-methods__card-title {
  font-size: 18px;
  font-weight: bold;
  color: white;
}
.button {
  padding-top: 2rem;
  padding-bottom: 0;
}
.learning-methods__button {
  background-color: #4785ff;
  color: #fff;
  border: #4785ff;
  border-radius: 1rem;
  padding: 15px 25px;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
}

.learning-methods__button:hover {
  background-color: #444;
}

.container {
  display: flex;
  flex-direction: row;
  gap: 5rem;
}

.lastimg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin-left: 5rem;
  margin-bottom: 3rem;
  margin-left: 2rem;
}

.lastimg img {
  max-width: 90%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
}

.contactUs {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.contactUs > p {
  color: #ffa4d5;
  font-size: 2rem;
  padding: 3rem 5rem;
}

.contactUs > h4 {
  font-size: 3rem;
  font-weight: 600;
  padding: 0rem 5rem;
}

.contactUs1 {
  display: flex;
  padding: 0rem 0rem;
  justify-content: space-around;
}

.contactUs2 {
  display: flex;
  flex-direction: column;
}

.contactUs3 {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 3rem;
  gap: 1rem;
}

.contactImage {
  width: auto;
  height: 500px;
}

.contactUs4 > p {
  width: 20rem;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  color: #4d4d4d;
}

.contactUs4 > h5 {
  font-size: 3rem;
  font-weight: 600;
  margin: 0;
}

.contactUs4 {
  text-align: left;
}

.contactUs4 a {
  font-size: 1.5rem;
  text-decoration: none;
  color: #4d4d4d;
  font-weight: 600;
}

.contactUs5 {
  flex-direction: column;
  margin-top: 6rem;
  margin-left: 5rem;
}

.contactUs5 h5 {
  font-size: 3rem;
  font-weight: 600;
  margin: 0;
}

.contactUs6 {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  width: 300px;
  gap: 0.5rem;
}

.contactUs7 {
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 55px;
  height: 55px;
  background-color: #4785ff;
  border-radius: 50%;
  align-items: center;
}

/* ************************* Media Queries for Responsiveness **************************************** */

@media (max-width: 1024px) {
  .whyCodeBeeSection {
    padding: 1rem;
    display: block;
  }

  .whyChooseUs {
    margin: 2rem 1rem 2rem 1rem;
  }

  .whyCodeBee {
    margin: 0rem;
  }

  .collageImage {
    padding: 0;
  }

  .whyChooseUs h5 {
    font-size: 1.5rem;
  }

  .whyChooseUsText {
    font-size: 1rem;
  }

  .whyChooseUs span {
    font-size: 0.9rem;
  }

  .bulletPoints {
    font-size: 0.9rem;
  }

  .news {
    display: block;
    width: 100%;
    place-self: center;
  }

  .news1 {
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 800px) {
  .whyCodeBeeSection {
    display: block;
  }
}

@media (min-width: 1200px) {
  .whyCodeBeeSection {
    display: flex;
    justify-content: space-evenly;
  }

  .whyChooseUs {
    margin: 6rem 0rem 2rem 3rem;
  }
}

@media (max-width: 480px) {
  .buttonAndArrow {
    flex-direction: column;
    align-items: flex-start;

    svg {
      display: none;
    }
  }

  .diveInButton {
    width: 100%;
    margin-bottom: 1rem;
  }

  .whyChooseUs h5 {
    font-size: 1.5rem;
  }
}

@media (max-width: 768px) {
  .clientSaying h4 {
    font-size: 2rem;
    padding: 0;
    margin: 0;
  }

  .clientCards1 {
    margin: 0;
    margin-bottom: 3rem;
    padding: 0;
    width: 100%;
  }

  .clientsName {
    font-size: 1.1rem;
  }

  .commentIcon {
    height: 4rem;
    width: 4rem;
  }

  .clientsReview {
    height: auto;
  }

  .clientsThoughts {
    padding: 0;
    margin: 2rem 0rem;
    display: block;
    width: 100%;
  }

  .clientCards {
    display: block;
  }
}

@media (max-width: 1024px) {
  .aboutCodebee1 {
    margin: 3rem 3rem;
  }

  .visionDetails {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    margin: 0rem 3rem 5rem 3rem;
  }

  .vision > h4 {
    font-size: 3rem;
    text-align: center;
    margin-top: 2rem;
  }

  .vision p {
    width: 100%;
    font-size: 1rem;
  }

  .logo1 {
    width: 80%;
    height: auto;
  }
}

@media (max-width: 1024px) {
  .aboutCodebee1 {
    margin: 2rem 2rem;
  }

  .visionDetails {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    margin: 0rem 2rem 4rem 2rem;
  }

  .vision h4 {
    padding-top: 2rem;
    font-size: 2.5rem;
  }

  .vision p {
    width: 100%;
    font-size: 0.9rem;
  }

  .logo1 {
    width: 70%;
    height: auto;
  }
}

@media (max-width: 480px) {
  .aboutCodebee1 {
    margin: 1rem 1rem;
  }

  .visionDetails {
    margin: 0rem 1rem 3rem 1rem;
  }

  .vision h4 {
    padding-top: 2rem;
    font-size: 2rem;
  }

  .vision p {
    font-size: 0.8rem;
    width: 100%;
  }

  .logo1 {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 1024px) {
  .shapingCodebee1 {
    margin: 3rem;
  }

  .shapingCodebee1 h4 {
    font-size: 2.5rem;
  }

  .shapingCodebee1 p {
    font-size: 1.1rem;
  }

  .shapingCodebee2 {
    grid-template-columns: 1fr 1fr;
    margin: 3rem;
  }

  .hexagonImage {
    width: 7rem;
    height: 6rem;
  }
}

@media (max-width: 768px) {
  .shapingCodebee1 {
    margin: 2rem;
  }

  .shapingCodebee1 h4 {
    font-size: 2rem;
  }

  .shapingCodebee1 p {
    font-size: 1rem;
  }

  .shapingCodebee2 {
    grid-template-columns: 1fr;
    margin: 2rem;
  }

  .hexagonImage {
    width: 6rem;
    height: 5rem;
  }
}

@media (max-width: 480px) {
  .shapingCodebee1 {
    margin: 1rem;
  }

  .shapingCodebee1 h4 {
    font-size: 1.8rem;
  }

  .shapingCodebee1 p {
    font-size: 0.9rem;
  }

  .shapingCodebee2 {
    grid-template-columns: 1fr;
    margin: 1rem;
  }

  .hexagonImage {
    width: 5rem;
    height: 4rem;
  }
}

@media (max-width: 1024px) {
  .inspiringContent {
    width: 90%;
    margin: 3rem auto;
    display: block;
  }

  .inspiringContent1 h4 {
    font-size: 2.5rem;
    text-align: center;
  }

  .inspiringContent44 p {
    font-size: 1.2rem;
  }

  .inspiringContent44 span {
    font-size: 0.9rem;
  }

  .inspiringContent6 p {
    font-size: 1.1rem;
    padding: 2rem;
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .inspiringContent2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .inspiringContent3 {
    width: 100%;
    display: flex;
    justify-content: stretch;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .inspiringContent {
    width: 95%;
    margin: 2rem auto;
    display: block;
  }

  .inspiringContent1 h4 {
    font-size: 2rem;
    text-align: center;
  }

  .inspiringContent44 p {
    font-size: 1rem;
  }

  .inspiringContent44 span {
    font-size: 0.8rem;
  }

  .inspiringContent6 p {
    font-size: 1rem;
    padding: 1.5rem;
  }

  .inspiringContent2 {
    padding-top: 1vh;
    padding-bottom: 2vh;
  }

  .inspiringContent2 {
    display: block;
    width: 100%;
    place-items: center;
  }

  .inspiringContent3 {
    width: 400px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 2rem 0rem 1rem 0rem;
  }
}

@media (max-width: 480px) {
  .inspiringContent {
    width: 100%;
    margin: 1rem auto;
  }

  .inspiringContent1 h4 {
    font-size: 1.8rem;
  }

  .inspiringContent44 p {
    font-size: 0.9rem;
  }

  .inspiringContent44 span {
    font-size: 0.75rem;
  }

  .inspiringContent6 p {
    font-size: 0.9rem;
    padding: 1rem;
  }
}

@media (max-width: 1000px) {
  .teamSection > h4 {
    text-align: center;
    padding: 4rem 0rem;
  }

  .cardWrapper {
    display: block;
    padding: 0;
    place-items: center;
  }

  .profileCard {
    margin-bottom: 3rem;
  }
}


@media only screen and (max-width: 1024px) {
  .footer1 {
    margin: 3rem 2rem 1.5rem 2rem;
    padding: 2rem 1rem;
  }

  h2 {
    font-size: 3rem;
    text-align: center;
  }

  .footer3 {
    flex-direction: column;
    align-items: center;

    p {
      text-align: center;
    }
  }

  .footer4,
  .footer5,
  .footer6 {
    flex-basis: 100%;
    margin-bottom: 2rem;
    text-align: center;
  }

  .footer2 {
    flex-direction: column;
    align-items: center;
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 480px) {
  .footer1 {
    margin: 2rem 1rem;
    padding: 1.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  .footer3 {
    gap: 1rem;
  }

  .footer4 a,
  .footer5 a {
    font-size: 0.9rem;
  }

  .footer8 {
    justify-content: space-evenly;
  }
}

/* ************** MediaQuery **************** */

@media (max-width: 1200px) {
  .herosection {
    flex-direction: column; /* Stack elements vertically */
  }

  .detailsSection,
  .cardsSection {
    width: 100%;
    padding: 2rem 1rem 0rem 1rem;
  }

  .spanHeading {
    font-size: 1.2rem;
  }

  .detaildsHeading {
    font-size: 2.5rem;
  }

  .subheading {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .spanHeading {
    font-size: 1rem;
  }

  .detaildsHeading {
    font-size: 2rem;
  }

  .subheading {
    font-size: 0.8rem;
  }
}

@media (max-width: 768px) {
  .faqSection {
    padding: 0.5rem;
    display: block;
  }

  h1 {
    margin-left: 5%;
    margin-right: 5%;
    font-size: 18px;
    text-align: center;
  }

  .frame {
    margin: 40px 10px;
    padding: 10px;
    width: 95%;
  }

  .gallery {
    gap: 15px;
  }

  .image-container {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  .image-container img {
    width: 100%;
    height: auto;
  }

  .description {
    font-size: 16px;
    padding: 8px;
    text-align: center;
  }

  .how {
    border-top-left-radius: 1.3rem;
    border-top-right-radius: 1.3rem;
  }

  .ratingcard {
    border-top-left-radius: 1.3rem;
    border-top-right-radius: 1.3rem;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 16px;
  }

  .frame {
    margin: 20px 5px;
  }

  .image-container {
    height: auto;
  }

  .description {
    font-size: 14px;
  }
}

@media (max-width: 1200px) {
  .contactUs {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contactUs p {
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  .contactUs h4 {
    text-align: center;
    margin: 0;
    padding: 0;
    margin-bottom: 4rem;
  }

  .contactUs1 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .contactUs2 {
    margin-top: 5rem;
    gap: 3rem;
  }
  
  .contactUs3 {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .contactUs4 h5 {
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .contactUs4 p {
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .contactImage {
    width: 100%;
    height: auto;
  }

  .contactUs5 {
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}


@media (max-width: 1200px) {
  .pSection {
    width: 100%;
    height: 100%;
  }

  .pSection span {
    margin: 1rem 1rem;

  }

  .pSection h5 {
    margin: 0;
    display: flex;
    justify-content: center;
    font-size: 2.9rem;
    margin-top: 1rem;
    text-align: center;
  }

  .pSection p {
    font-size: 1rem;
    text-wrap: pretty;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 1rem;
    text-align: center;
  }

  .pSection br {
    display: none;
  }

  .pSection1 {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .pSection2 {
    display: flex;
    flex-direction: column;
  }

  .pSection3 span {
    padding: 0;
    margin: 0;
  }

  .pSection2 h6 {
    text-align: center;
  }

  .pSection3 {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-evenly;
  }

  .pSection4 {
    margin: 0;
    padding: 0;
    justify-content: center;
    gap: 0rem;
  }

  .our_programs {
    width: 100%;
    height: 100%;
  }

  .cat1 {
    font-size: 2.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0;
  }

  .explore {
    font-size: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-wrap: pretty;

    br {
      display: none;
    }
  }

  .news0 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .news {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    padding-left: 5rem;
    padding-right: 5rem;

    br {
      display: none;
    }
  }

  .cards {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .learning-methods {
    width: 100%;
    height: 100%;
  }

  .container {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .learning-methods__title {
    margin: 0;
    padding: 0;
    font-size: 1.3rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .learning-methods__heading {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .learning-methods__text {
    margin: 0;
    padding: 0;
    text-align: center;

    br {
      display: none
    };
  }

  .fullback {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .learning-methods__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .learning-methods__card {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  .learning-methods__card-icon {
    width: 50px;
    height: 50px;
  }

  .learning-methods__card-title {
    font-size: 1.5rem;
  }

  .learning-methods__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20rem;
    font-size: 1.5rem;
  }

  .lastimg {
    margin: 0;
    width: 400px;
  }
}

@media (max-width: 1024px) {


  .news0 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .news {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 3rem;
  }

  .news1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    widows: 100%;
    height: auto;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .news2 {
    padding: 0;
    margin: 0;
  }

  .news2 p {
    text-align: center;
    width: 100%;
    text-wrap: pretty;
    margin: 0;
    padding: 0;
  }

  .cer {
    margin: 0;
    padding: 0;
    margin-top: 1rem;
    text-align: center;
  }
}


@media (max-width: 1200px) {
  .title h2 {
    font-size: 1.1rem
  }
}